<template>
    <template v-if="isExternal">
        <a v-if="disabled" disabled v-bind="$attrs" :href="to || '/'" target="_blank" @click="handleClick"
            ><slot>{{ label }}</slot> <span v-if="suffix" class="font-sans">→</span>
        </a>
        <a v-else v-bind="$attrs" :href="to || '/'" target="_blank" @click="handleClick"
            ><slot>{{ label }}</slot> <span v-if="suffix" class="font-sans">→</span>
        </a>
    </template>
    <template v-else>
        <RouterLink v-if="disabled" disabled :to="`${localePath(to || '/')}${anchorPoint}`" v-bind="$attrs" @click="handleClick"
            ><slot>{{ label }}</slot> <span v-if="suffix" class="font-sans">→</span></RouterLink
        >
        <RouterLink v-else :to="`${localePath(to || '/')}${anchorPoint}`" v-bind="$attrs" @click="handleClick"
            ><slot>{{ label }}</slot> <span v-if="suffix" class="font-sans">→</span></RouterLink
        >
    </template>
</template>

<script setup lang="ts">
declare var gtag: any;
let props = withDefaults(
    defineProps<{
        to?: any;
        suffix?: boolean;
        params?: string;
        anchorPoint?: string; // 锚点
        disabled?: boolean;
        label?: string;
    }>(),
    {
        to: "",
        suffix: false,
        params: "",
        anchorPoint: "",
        disabled: false,
        label: "",
    }
);

function validateUrl(url: string) {
    return /^(http|https)/.test(url);
}
const isExternal = computed(() => validateUrl(props.to));
const localePath = useLocalePath();
// console.log("localePath", localePath);

function handleClick() {
    if (props.to === "https://app.nearhub.us/") {
        (window as any).gtag && gtag("event", "to_canvas_app");
    }
}
</script>

<style scoped></style>
